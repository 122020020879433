<template>
	<div class="col-12 col-lg-9">
        <section class="userSteps bg-white boxed max-1085 p-md-5">
            <div clas="row">
                <div class="col-12 mb-4 pb-1">
                    <h4 class="mb-0">{{$t('change_password.change_passwordtitle')}}</h4>
                    <p class="f-14 mb-0">{{$t('change_password.change_passwordcontent10')}}</p>
                </div>
            </div>
            <div class="vuewFullSocialFeed myPost row largeInput">
                <div class="col-12 col-md-6">
                    <Form @submit="submit()" ref="form">
                        <!-- <div class="form-group mb-5">
                            <Field type="password" class="form-control" name="Old Password" placeholder="Old password" v-model="oldPassword" rules="required|min:8"/>
                            <ErrorMessage class="text-danger f-12" name="Old Password" />
                        </div> -->
                        <div class="mb-3">
                            <div class="form-group mb-1">
                                <div class="position-relative">
                                    <Field :type="showPassword ? 'text' : 'password'" class="form-control" name="Password" :placeholder="$t('change_password.change_passwordcontent9')" v-model="password" rules="required|min:2"/>
                                    <vue-feather :type="showPassword ? 'eye' : 'eye-off'" class="position-absolute eye pointer searchIcon me-2 searchIcon-auto d-flex" @click="showPassword = !showPassword"></vue-feather>
                                </div>
                                <ErrorMessage class="text-danger f-12" name="Password" />
                            </div>
                            <!-- <div class="d-flex align-items-center justify-content-end" :class="(!validPassword && password) ? 'orange' : (validPassword && password) ? 'green' : ''">
                                    <p class="f-10 mb-0">{{$t('change_password.change_passwordcontent1')}} </p>
                                    <div class="tooltipbutton">
                                        <span class="tooltiptext">
                                            <div class="strenghtPassword m-0">
                                                <ul class="passwordStrenth mb-0">
                                                    <li :class="(upperCaseOk) ? 'green' : 'red'">{{$t('change_password.change_passwordcontent2')}}</li>
                                                    <li :class="(password.length >= 8) ? 'green' : 'red'">{{$t('change_password.change_passwordcontent3')}} </li>
                                                    <li :class="(numberOk) ? 'green' : 'red'">{{$t('change_password.change_passwordcontent4')}} </li>
                                                    <li :class="(letterOk) ? 'green' : 'red'">{{$t('change_password.change_passwordcontent5')}} </li>
                                                    <li :class="(SpecialCharOk) ? 'green' : 'red'">{{$t('change_password.change_passwordcontent6')}} </li>
                                                </ul>
                                            </div>
                                        </span>
                                        <vue-feather class="mx-1 alertType" :type="(!validPassword && password) ? 'alert-circle' : (validPassword && password) ? 'check-circle' : 'alert-circle'" size="18"></vue-feather>
                                    </div>
                                    <span class="barPass"></span>
                            </div> -->
                        </div>
                        <div class="form-group">
                            <div class="position-relative">
                                <Field type="password" class="form-control" name="Retype password" v-model="retypePassword" :placeholder="$t('change_password.change_passwordcontent8')"  rules="required|confirmed:@Password"/>
                                <!-- <vue-feather type="eye" class="position-absolute eye pointer searchIcon me-2 searchIcon-auto d-flex"></vue-feather> -->
                            </div>
                            <ErrorMessage class="text-danger f-12" name="Retype password" />
                        </div>
                        <div class="mt-4 border-0">
                            <button type="submit" class="button fillBtn zulu_btn d-flex px-md-5"  :class="{'disabled' : store.customerDetail?.readOnly}">{{$t('change_password.change_passwordcontent7')}}</button>
                        </div>
                    </Form>
                </div>
            </div>
        </section>
	</div>
</template>
<script>
    import { myStore } from "@/store/pinia-store";
    import { Form, Field, ErrorMessage } from "vee-validate";
    export default {
        setup() {
            const store = myStore();
            return { store };
        },
        components: { Form, Field, ErrorMessage },
        data(){
            return {
                oldPassword: '',
                password: '',
                retypePassword: '',
                upperCaseOk : false,
                numberOk : false,
                SpecialCharOk : false,
                letterOk : '',
                validPassword : false,
                showPassword: false
            }
        },
        watch: {
            "password": function (val) {
                let upperCase = new RegExp("[A-Z]"),
                numbers = new RegExp("[0-9]"),
                letter = new RegExp("[A-Za-z]"),
                specialcharacter = new RegExp("[!,%,&,@,#,$,^,*,?,_,~,/]");
                if (val.match(upperCase)) {
                    this.upperCaseOk = true
                }else{
                    this.upperCaseOk = false
                }
                if(val.match(numbers)){
                    this.numberOk = true
                }else{
                    this.numberOk = false
                }
                if (val.match(letter)) {
                    this.letterOk = true;
                } else {
                    this.letterOk = false;
                }
                if (val.match(specialcharacter)) {
                    this.SpecialCharOk = true;
                } else {
                    this.SpecialCharOk = false;
                }
                if(this.upperCaseOk && this.numberOk && this.SpecialCharOk && val.length >= 8 && this.letterOk){
                    this.validPassword = true
                }else{
                    this.validPassword = false
                }

            }
        },
        methods: {
            submit(){
                let formdata = {}
                formdata['newPassword'] = this.password
                this.store.changePassword(formdata,false).then(()=>{
                    this.$refs.form.resetForm();
                })
            },
        },
        created() {
            
        },
   
    };
</script>