<template>
    <div class="col-12 col-lg-9 position-relative">
        <h3 class="medium f-28 darkblueColor mb-0">{{$t('changes.text34')}}</h3>
        <Loader :classname="'innerLoader'" v-if="store.singleLoading"></Loader>
        <div class="customTableStr" v-if="Object.keys(allNotification).length">
            <div class="listNotify">
                <template v-for="data,k in allNotification" :key="k">
                    <div class="bg-white br-20 mt-3 p-3">
                        <p class="bold f-20 mb-0">{{capitalize(k)}}</p>
                        <div class="d-flex align-items-end justify-content-between">
                            <div class="d-flex align-items-center">
                                &nbsp;
                            </div>
                            <div class="d-flex">
                                <div class="d-inline-block email-notify">
                                    <h6 class="f-14 medium mb-3">{{$t('changes.text38')}}</h6>
                                </div>
                                <div class="d-inline-flex justify-content-center push-notify">
                                    <h6 class="f-14 medium mb-3">{{$t('changes.text39')}}</h6>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex align-items-end justify-content-between py-3 bordergSB" v-for="list,index in data" :key="index">
                            <div class="d-flex align-items-center">
                                <div class="d-block">
                                    <h6 class="mb-0 regular f-15">{{labelNotification[list.id]}}</h6>
                                </div>
                            </div>
                            <div class="d-flex">
                                <template v-for="medium in ['EMAIL','PUSH']" :key="medium">
                                    <div class="d-inline-flex justify-content-center" :class="medium.toLowerCase()+'-notify'">
                                        <label class="switch" :class="[{ 'disabled': !list[medium] },{'disabled': list[medium] && list[medium].readOnly}]">
                                            <input v-if="list[medium]" type="checkbox" v-model="allNotification[k][index][medium].enabled">
                                            <input v-else type="checkbox">
                                            <span class="slider round"></span>
                                        </label>
                                    </div>
                                </template>
                            </div>
                        </div>
                    </div>
                </template>
            </div>
        </div>
        <div class="d-flex align-items-end justify-content-start py-4" v-if="Object.keys(allNotification).length">
            <button class="medium px-md-5 button zulu_btn fillBtn rounded me-2" :class="{'disabled' : store.customerDetail?.readOnly}" @click="postNotification()">{{$t('changes.text40')}}</button>
            <button class="medium px-md-5 button rounded border-button zulu_btn" @click="getNotification()">{{$t('changes.text41')}}</button>
        </div>
        <Nodata v-if="!Object.keys(allNotification).length"></Nodata>
    </div>
</template>
<script>
    import { myStore } from "@/store/pinia-store";
    export default {
        setup() {
            const store = myStore();
            return { store };
        },
        components: {},
        data() {
            return {
                labelNotification: {
                    1: this.$t('changes.text50'),
                    2: this.$t('changes.text51'),
                    3: this.$t('changes.text52'),
                    5: this.$t('changes.text53'),
                    100: this.$t('changes.text54'),
                    101: this.$t('changes.text55'),
                    102: this.$t('changes.text56'),
                    103: this.$t('changes.text57'),
                    105: this.$t('changes.text58'),
                    106: this.$t('changes.text59'),
                    150: this.$t('changes.text60'),
                    151: this.$t('changes.text61'),
                    152: this.$t('changes.text62'),
                    153: this.$t('changes.text63'),
                    155: this.$t('changes.text64'),
                    160: this.$t('changes.text65'),
                    161: this.$t('changes.text66'),
                    162: this.$t('changes.text67'),
                    163: this.$t('changes.text68'),
                    164: this.$t('changes.text69'),
                    165: this.$t('changes.text70'),
                    166: this.$t('changes.text71'),
                    167: this.$t('changes.text72'),
                    168: this.$t('changes.text73'),
                    169: this.$t('changes.text74'),
                    170: this.$t('changes.text75'),
                    171: this.$t('changes.text76'),
                    172: this.$t('changes.text77'),
                    173: this.$t('changes.text78'),
                    174: this.$t('changes.text79'),
                    175: this.$t('changes.text80'),
                    201: this.$t('changes.text81'),
                    202: this.$t('changes.text82'),
                    300: this.$t('changes.text83'),
                    400: this.$t('changes.text84'),
                    401: this.$t('changes.text85'),
                    800: this.$t('changes.text86'),
                    600: this.$t('changes.text87'),
                    601: this.$t('changes.text88'),
                    606: this.$t('changes.text89'),
                    707: this.$t('changes.text90'),
                    808: this.$t('changes.text91'),
                    900: this.$t('changes.text92'),
                    901: this.$t('changes.text93'),
                    902: this.$t('changes.text47'),
                },
                allNotification: {},
            };
        },
        watch:{
            'store.userSelectedAccount'(){
                if(this.store.user.access_token){
                    this.getNotification()
                }
            },
            'store.allTypeAccount'(){
                if(this.store.user.access_token && this.store.allTypeAccount){
                    this.getNotification()
                }
            }
        },
        methods: {
            getNotification(){
                this.store.notificationSettings({}, true, 'get').then(res=>{
                    if(res){
                        let newObj = {}
                        res.forEach(v=>{
                            if(!newObj[v.category]){ newObj[v.category] = []; }
                            newObj[v.category].push({
                                "category": v.category,
                                "id": v.id,
                                'PUSH' : v.supportedMediums.find(v=>v.medium == 'PUSH'),
                                'EMAIL' : v.supportedMediums.find(v=>v.medium == 'EMAIL')
                            })
                        })
                        this.allNotification = newObj
                    }
                })
            },
            postNotification(){
                let sendData = [];
                for(const k in this.allNotification){
                    for(const i in this.allNotification[k]){
                        ['EMAIL','PUSH'].forEach(medium=>{
                            if(this.allNotification[k][i][medium]){
                                sendData.push({
                                    "notificationTypeId": this.allNotification[k][i].id,
                                    "transmissionType": this.allNotification[k][i][medium].medium,
                                    "enabled": this.allNotification[k][i][medium].enabled
                                })
                            }
                        })
                    }
                }
                this.store.notificationSettings(sendData, true, 'put')
            },
            capitalize(str){
                let a = str.replace('_',' ').toLowerCase().split(" ")
                for (var i = 0; i < a.length; i++) {
                    a[i] = a[i].charAt(0).toUpperCase() + a[i].slice(1);
                }
                return a.join(" ");
            },
        },
        created() {
            this.getNotification()
        }
    };
</script>