<template>
    <div class="col-12 col-lg-9 position-relative">
        <h3 class="medium f-28 darkblueColor mb-3">{{$t('acountSetting.acText1')}}</h3>
        <Loader :classname="'innerLoader'" v-if="store.singleLoading"></Loader>
        <div class="customTableStr bg-white br-20 mt-3">
            <div class="listNotify" v-if="Object.values(store.brokerAccountSettings).length">
                <!-- <div class="d-flex align-items-start justify-content-between p-3 bordergSB">
                    <div class="d-flex align-items-start moduleSets">
                        <span class="flex-shrink-0 mt-1 me-2"><v-lazy-image width="24" height="24" class="d-block" src="/assets/images/funded/settingIcons-6.svg" alt="Account settings" /></span>
                        <div class="settingTitle">
                            <p class="f-17 medium mb-1">Monitor My Account</p>
                            <p class="f-12 mb-0">This feature offers an extra layer of defense to your account, it protects your equity by closing trades that were expected to close, but due to rejection or failure to deliver one or more trading signals to your broker, they remained unmodified at market. By checking this option, you authorize the ZuluTrade engine to close any traced trade at the minimum possible loss or at any profit in cases that this is within its potential.</p>
                        </div>
                    </div>
                    <div class="d-inline-flex justify-content-end mt-2 moduleSlider">
                        <label class="switch">
                            <input type="checkbox" v-model="store.brokerAccountSettings.monitorMyAccount">
                            <span class="slider round"></span>
                        </label>
                    </div>
                </div> -->
                <div class="d-flex align-items-start justify-content-between p-3 bordergSB">
                    <div class="d-flex align-items-start moduleSets">
                        <span class="flex-shrink-0 mt-1 me-2"><v-lazy-image width="24" height="24" class="d-block" src="/assets/images/funded/settingIcons-8.svg" :alt="$t('acountSetting.acText1')" /></span>
                        <div class="settingTitle">
                            <p class="f-17 medium mb-1">{{$t('acountSetting.acText2')}} </p>
                            <p class="f-12 mb-0">{{$t('acountSetting.acText3')}} </p>
                        </div>
                    </div>
                    <div class="d-inline-flex justify-content-end mt-2 moduleSlider">
                        <label class="switch">
                            <input type="checkbox"  v-model="store.brokerAccountSettings.ignoreConfirmationPopupWhenClosingPosition">
                            <span class="slider round"></span>
                        </label>
                    </div>
                </div>
            </div>
            <div class="listNotify" v-if="Object.values(store.customerDetail).length">
                <div class="p-3">
                    <div class="d-flex align-items-start moduleSets">
                        <span class="flex-shrink-0 mt-1 me-2"><v-lazy-image width="24" height="24" class="d-block" src="/assets/images/funded/settingIcons-1.svg" :alt="$t('acountSetting.acText4')" /></span>
                        <div class="settingTitle">
                            <p class="f-17 medium mb-1">{{$t('acountSetting.acText4')}} </p>
                            <p class="f-12">{{$t('acountSetting.acText5')}} </p>
                            <label class="cutomChechBox">
                                {{$t('acountSetting.acText6')}}
                                <input type="checkbox" v-model="store.customerDetail.emailNotification">
                                <span class="checkmark"></span>
                            </label>
                            <label class="cutomChechBox">
                                {{$t('acountSetting.acText7')}}
                                <input type="checkbox" v-model="store.customerDetail.phoneNotification">
                                <span class="checkmark"></span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="d-flex align-items-end justify-content-start py-4">
            <button class="medium px-md-5 button zulu_btn fillBtn rounded me-2" :class="{'disabled' : store.customerDetail?.readOnly}" @click="postAccountSetting()">{{$t('changes.text40')}}</button>
            <button class="medium px-md-5 button rounded border-button zulu_btn" @click="getAccountSetting();getNewsletterSettings()">{{$t('changes.text41')}}</button>
        </div>
        <Nodata v-if="!Object.values(store.brokerAccountSettings).length && !Object.values(store.customerDetail).length"></Nodata>
    </div>
</template>
<script>
    import { myStore } from "@/store/pinia-store";
    export default {
        setup() {
            const store = myStore();
            return { store };
        },
        components: {},
        data() {
            return {
            };
        },
        watch:{
            "store.userSelectedAccount"(){
                if(this.store.user.access_token){
                    this.getAccountSetting()
                }
            },
            'store.allTypeAccount'(){
                if(this.store.user.access_token && this.store.allTypeAccount){
                    this.getAccountSetting()
                }
            }
        },
        methods: {
            getAccountSetting(){
                this.store.accountSettings({}, true, 'get')
            },
            getNewsletterSettings(){
                this.store.callCustomerDetail({}, false)
            },
            postAccountSetting(){
                let payLoad = {
                    // 'monitorMyAccount': this.store.brokerAccountSettings.monitorMyAccount,
                    'ignoreConfirmationPopupWhenClosingPosition': this.store.brokerAccountSettings.ignoreConfirmationPopupWhenClosingPosition
                }
                this.store.accountSettings(payLoad, true, 'post')
                let payLoadNewsletter = {
                    "emailNotification": this.store.customerDetail.emailNotification,
                    "phoneNotification": this.store.customerDetail.phoneNotification
                }
                this.store.newsletterSettings(payLoadNewsletter, true)
            },
        },
        created() {
            this.getAccountSetting()
            this.getNewsletterSettings()
        }
    };
</script>