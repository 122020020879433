<template>
    <!--  <SidebarLeft></SidebarLeft> -->
    <div class="col-12 col-lg-9">
        <Upgrade />
        <section class="userSteps bg-white boxed max-1085">
            <div clas="row">
                <div class="col-12">
                    <h4 class="mb-4">{{$t('personal_detail.detailtitle')}}</h4>
                </div>
            </div>
            <div class="publicProfile row">
                <div class="col-12 col-lg-6">
                    <Form class="personalDetails" @submit="submit">
                        <float-label class="form-group">
                            <Field class="form-control" name="first name" :placeholder="$t('personal_detail.placeolderName')" v-model="form.fname" rules="required" />
                            <ErrorMessage class="text-danger f-14" name="first name" />
                        </float-label>
                        <float-label class="form-group">
                            <Field class="form-control" name="last name" :placeholder="$t('personal_detail.placeolderLast')" v-model="form.lname" rules="required" />
                            <ErrorMessage class="text-danger f-14" name="last name" />
                        </float-label>
                        <float-label class="form-group">
                            <div class="countrySelect form-control position-relative filter-dropdown"  :class="[{'disabled' : store.userDetail.completed && country?.name},{'selected' : selectButton}]">
                                <a @click="selectButton = !selectButton" href="javascript:void(0)" class="d-flex align-items-center justify-content-between">
                                    <span class="d-flex align-items-center" v-if="country.id && country.name">
                                        <v-lazy-image class="me-2" :src="'/assets/images/country_flag/'+country.name.toLowerCase()+'.webp'" :alt="$t('personal_detail.detailcontent13')" :title="$t('personal_detail.detailcontent13')" loading="lazy" width="20" />{{country.name}}
                                    </span>
                                    <span v-else>{{$t('personal_detail.detailcontent1')}} </span>
                                    <vue-feather size="20" type="chevron-down"></vue-feather>
                                </a>
                                <label class="afterSelect">{{$t('personal_detail.detailcontent2')}}</label>
                                <ul class="dropdown_menu_animated scrollable" :class="selectButton ? 'show' : ''">
                                    <li class="form-group mb-1">
                                        <input type="text" :placeholder="$t('personal_detail.detailcontent18')" class="form-control radius-0" v-model="countrysearch" />
                                    </li>
                                    <li v-for="item,key in getCountries" :key="key">
                                        <a href="javascript:void(0)" class="d-flex align-items-center" @click="country = item;selectButton = false;countrysearch = '';changecountry(item)">
                                            <v-lazy-image class="me-2" :src="'/assets/images/country_flag/'+item.name.toLowerCase()+'.webp'" :alt="$t('personal_detail.detailcontent13')" :title="item.name" loading="lazy" width="20" /> {{item.name}}
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <span v-if="isCountry" role="alert" class="text-danger">{{$t('changes.text42')}}</span>
                        </float-label>
                        <float-label class="form-group position-relative">
                            <Field class="form-control emailId" name="email" :placeholder="$t('personal_detail.placeolderEmail')" v-model="form.email" rules="required" :disabled="(store.userDetail.email && store.userDetail.emailVerified) ? true : false" />
                            <ErrorMessage class="text-danger" name="email" />
                            <div class="loaderButton spinner-border primary" role="status" v-if="loader && !showEmailCode">
                                <span class="visually-hidden">{{$t('personal_detail.detailcontent3')}}</span>
                            </div>
                            <a href="javascript:void(0)" class="getCode semibold" v-if="!loader && validMail && !store.userDetail.emailVerified" @click="SubmitData('Email')">{{ !showEmailCode ? $t('personal_detail.detailcontent14') : $t('personal_detail.detailcontent15') }}</a>
                            <span class="verifyed semibold" v-if="store.userDetail.emailVerified && store.userDetail.email">{{$t('personal_detail.detailcontent4')}}</span>
                        </float-label>
                        <float-label class="form-group pdataEmail" v-if="showEmailCode">
                            <v-otp-input separator="" input-classes="otp-input" :num-inputs="6" :shouldAutoFocus="true" @on-change="handleOnChange" @on-complete="handleOnComplete" :autoFormat="false" />
                            <Field style="display: none;" class="form-control" name="otp" placeholder="" v-model="form.emailcode" rules="required|min:6" />
                            <ErrorMessage class="text-danger" name="otp" />
                            <a href="javascript:void(0)" class="getCode semibold" @click="verifyEmailOTP()">
                                <div class="loaderButton spinner-border primary veryLoader" role="status" v-if="loader && showEmailCode">
                                    <span class="visually-hidden">{{$t('personal_detail.detailcontent3')}}</span>
                                </div>
                                <span v-else>{{$t('personal_detail.detailcontent16')}}</span>
                            </a>
                        </float-label>
                        <float-label class="form-group phoneNumber">
                            <div class="belowCustomInput mb-2 position-relative" :class="selectNumber ? 'selected' : ''">
                                <Field v-slot="{ field }" name="Phone Number" class="form-control" v-model="form.mobile">
                                    <vue-tel-input v-bind="field" v-model="form.mobile" @change="onInput" @country-changed="GetCode" :defaultCountry ="form.phonecode" :onlyCountries ="getAllowCountry()" ref="phone" :inputOptions="options"></vue-tel-input>
                                </Field>
                                <div class="importantInstruction text-end">
                                    <span class="verifyed semibold" v-if="store.userDetail.phoneVerified && store.userDetail.phoneVerified && !validPhone">{{$t('personal_detail.detailcontent4')}}</span>
                                    <!-- <a href="javascript:void(0)" class="getCode semibold" v-if="validPhone" @click="SubmitData('Phone')">Get Code</a> -->
                                    <div class="loaderButton spinner-border primary" role="status" v-if="phoneloader && !showSMSCode">
                                        <span class="visually-hidden">{{$t('personal_detail.detailcontent3')}}</span>
                                    </div>
                                    <a href="javascript:void(0)" class="getCode semibold" v-if="!phoneloader && validPhone && !store.userDetail.phoneVerified" @click="SubmitData('Phone')">{{ (!showSMSCode ? $t('personal_detail.detailcontent14') : (showOtp) ? `${countTime}s` : $t('personal_detail.detailcontent15')) }} </a>
                                    <div class="tooltipbutton" v-if="PhoneInstruction">
                                        <span class="tooltiptext f-12">
                                            <ul class="dotList">
                                                <li>{{$t('changes.text1')}}</li>
                                                <li>{{$t('changes.text2')}}</li>
                                                <li>{{$t('changes.text3')}}</li>
                                                <li>{{$t('changes.text4')}}</li>
                                            </ul>
                                        </span>
                                        <p class="alertNumber d-flex align-items-center mb-0 f-12">{{$t('changes.text48')}} <vue-feather class="smallSize ms-1" type="alert-circle"></vue-feather></p>
                                    </div>
                                </div>
                            </div>
                            <span v-if="!isPhone" role="alert" class="text-danger">{{$t('changes.text4')}}</span>
                        </float-label>
                        <float-label class="form-group" v-if="showSMSCode">
                            <Field type="number" class="form-control" name="mobile otp" :placeholder="$t('personal_detail.placeolderOtp')" v-model="form.smscode" rules="required" />
                            <ErrorMessage class="text-danger f-14" name="mobile otp" />
                            <!-- <a href="javascript:void(0)" class="getCode semibold" @click="verifySMSOTP()">Verify</a> -->
                            <a href="javascript:void(0)" class="getCode semibold" @click="verifySMSOTP()">
                                <div class="loaderButton spinner-border primary veryLoader" role="status" v-if="phoneloader && showSMSCode">
                                    <span class="visually-hidden">{{$t('personal_detail.detailcontent3')}}</span>
                                </div>
                                <span v-else>{{$t('personal_detail.detailcontent5')}}</span>
                            </a>
                        </float-label>
                        <float-label class="form-group">
                            <Field class="form-control" name="display name" :placeholder="$t('personal_detail.placeolderDisplay')" v-model="form.username" rules="required"/>
                             <ErrorMessage class="text-danger f-14" name="display name" />
                        </float-label>
                        <div class="buttonElement mb-3">
                            <button type="submit" class="button fillBtn zulu_btn px-md-5" :class="[{'disabled' : !captchaToken || !country.id || (store.userDetail.email && !store.userDetail.emailVerified) || store.customerDetail?.readOnly}]" v-if="!store.singleLoading">
                                {{$t('personal_detail.detailcontent6')}}
                            </button>
                            <button type="submit" class="button fillBtn zulu_btn px-md-5" v-if="store.singleLoading"><i class="me-2 fa fa-spinner fa-spin"></i> {{$t('personal_detail.detailcontent3')}}</button>
                        </div>
                    </Form>
                </div>
                <div class="col-12 col-lg-6">
                    <div class="profilePicUpdate text-center">
                        <div class="text-center">
                            <h4 class="mb-2 medium">{{$t('personal_detail.detailcontent7')}} </h4>
                            <div class="tooltipbutton">
                                <span class="tooltiptext f-12">
                                    <p class="mb-2 line-2 f-14">{{$t('personal_detail.detailcontent8')}} </p>
                                    <ul class="dotList">
                                        <li>{{$t('personal_detail.detailcontent9')}} </li>
                                        <li>{{$t('personal_detail.detailcontent10')}} </li>
                                        <li>{{$t('personal_detail.detailcontent11')}} </li>
                                    </ul>
                                </span>
                                <p class="f-12 d-flex align-items-center justify-content-center mb-0">{{$t('personal_detail.detailcontent12')}}  <vue-feather class="smallSize ms-1" type="alert-circle"></vue-feather></p>
                            </div>
                        </div>
                        <div class="position-relative d-inline-flex justify-content-center my-5">
                            <v-lazy-image class="rounded-circle" width="160" height="160" :src="addfileImage(form.file)" v-if="form.name && store.userDetail.imageUrl" />
                            <v-lazy-image class="rounded-circle" width="160" height="160" :src="addfileImage(form.file)" v-if="form.name && !store.userDetail.imageUrl" />
                            <v-lazy-image class="rounded-circle" width="160" height="160" :src="store.userDetail.imageUrl+'?'+getTime()" v-if="store.userDetail.imageUrl && form.name == ''" />
                            <h2 v-if="!form.file.name && !store.userDetail.imageUrl && store.userDetail.firstName && store.userDetail.lastName">{{store.userDetail.firstName.charAt(0)}}{{store.userDetail.lastName.charAt(0)}}</h2>
                            <h2 v-if="!form.file.name && !store.userDetail.imageUrl && !store.userDetail.firstName && !store.userDetail.lastName">GU</h2>
                            <!--  <p v-if="form.file.name && !store.userDetail.imageUrl">{{form.file.name}}</p> -->
                            <!-- <span @click="clearDoc()" v-if="form.name && !store.userDetail.imageUrl"><vue-feather type="x"></vue-feather></span> -->
                            <Field type="file" name="File" accept="image/*,application/pdf" @change="setImage($event)" class="file-upload-input" id="fileUpload" rules="required" ref="document" style="display: none;" />
                            <a :title="$t('personal_detail.detailcontent17')" href="javascript:void(0)" class="position-absolute editable" @click="uploadDoc()">
                                <vue-feather size="20" type="upload"></vue-feather>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
    import { myStore } from "@/store/pinia-store";
    import { Form, Field, ErrorMessage } from "vee-validate";
    import FloatLabel from "vue-float-label/components/FloatLabel";
    import $ from "jquery";
    //import SidebarLeft from "@/views/dashboard-new/dashboard";
    import Upgrade from "./upgrade-header";
    import { load } from "recaptcha-v3";
    import { useToast } from "vue-toastification";
    export default {
        setup() {
            const store = myStore();
            //const Crypto = require("crypto-js");
            const toast = useToast();
            return { store, toast }; //Crypto
        },
        data() {
            return {
                selectButton: false,
                selectNumber: false,
                form: {
                    fname: "",
                    lname: "",
                    email: "",
                    emailcode: "",
                    mobile: "",
                    phonecode: "",
                    smscode: "",
                    username: "",
                    file: {},
                    name: "",
                },
                countrysearch: "",
                country: {},
                validMail: false,
                validPhone: false,
                showEmailCode: false,
                type: "",
                showSMSCode: false,
                loader: false,
                phoneloader: false,
                captchaToken: "",
                PhoneInstruction : false,
                countTime : 60,
                showOtp : false,
                options: { placeholder: this.$t('changes.text49')},
                isCountry: false,
                isPhone : true

            };
        },
        components: {
            FloatLabel,
            Form,
            Field,
            ErrorMessage,
            Upgrade,
            //SidebarLeft
        },
        watch: {
            "form.email"() {
                if (this.validEmail()) {
                    this.validMail = true;
                } else {
                    this.validMail = false;
                }
            },
            "form.mobile"(val) {
                if (val.length >= 7) {
                    this.validPhone = true;
                    this.isPhone = true
                    // if(parseInt(this.store.userDetail.phoneNumber) != parseInt(val)){
                    // }
                } else {
                    this.validPhone = false;
                }
            },
            "store.userDetail"(){
                if (Object.keys(this.store.userDetail).length) {
                    this.form.email = this.store.userDetail.email ? this.store.userDetail.email : "";
                    this.form.fname = this.store.userDetail.firstName ? this.store.userDetail.firstName : "";
                    this.form.lname = this.store.userDetail.lastName ? this.store.userDetail.lastName : "";
                    if (this.store.userDetail.countryIsoCodeA3) {
                        this.changecountry(this.getCountryData(this.store.userDetail.countryIsoCodeA3));
                    }
                    this.form.mobile = this.store.userDetail.phoneNumber ? this.store.userDetail.phoneNumber : "";
                    if (this.form.mobile) {
                        this.form.mobile = this.form.mobile.toString();
                    }
                    this.country = this.store.userDetail.countryIsoCodeA3 ? this.getCountryData(this.store.userDetail.countryIsoCodeA3) : "";
                    this.form.username = this.store.userDetail.profileName ? this.store.userDetail.profileName : "";
                }
            }
        },
        computed: {
            getCountries() {
                if (Object.keys(this.store.countriesList).length > 0) {
                    let data = Object.values(this.store.countriesList);
                    if (this.countrysearch) {
                        return data.filter((item) => item.name.toLowerCase().includes(this.countrysearch.toLowerCase()));
                    } else {
                        return data;
                    }
                } else {
                    return [];
                }
            },
        },
        methods: {
            successToast(){
                this.toast.success(this.$t('personal_detail.updateToast'));
            },
            getCount(){
                if(this.countTime > 0){
                    var interval = setInterval(()=>{
                        if(this.countTime > 0){
                            this.countTime = this.countTime-1
                        }else{
                            clearInterval(interval);
                            this.showOtp = false
                            this.showSMSCode = true
                            this.countTime = 60
                        }
                    },1000)
                }

            },
            SubmitData(type) {
                load(`${this.static_vars.recaptchaKey}`, { useRecaptchaNet: true }).then((recaptcha) => {
                    recaptcha.execute("login").then((token) => {
                        // console.log(token) // Will print the token
                        this.captchaToken = token;
                        this.type = type;
                        let formData = {};
                        if (type == "Email" && !this.store.userDetail.emailVerified && this.store.userDetail.email != this.form.email) {
                            formData["email"] = this.form.email;
                        } else if (type == "Phone" && this.store.userDetail.phoneNumber != this.form.mobile) {
                            formData["phoneCountryCode"] = this.form.phonecode;
                            formData["phoneNumber"] = this.form.mobile;
                            if (this.form.email && !this.store.userDetail.emailVerified && this.store.userDetail.email != this.form.email) {
                                formData["email"] = this.form.email;
                            }
                        }
                        if (this.country.id) {
                            formData["countryId"] = this.country.id;
                        }
                        if (this.country.isoCode) {
                            formData["countryIsoCodeA3"] = this.country.isoCode;
                        }
                        if (this.store.userDetail.customerId) {
                            formData["customerId"] = this.store.userDetail.customerId;
                        }
                        if (this.form.fname) {
                            formData["firstName"] = this.form.fname;
                        }
                        if (this.form.lname) {
                            formData["lastName"] = this.form.lname;
                        }
                        // if (this.form.username) {
                        //     formData["profileName"] = this.form.username;
                        // }
                        if (this.captchaToken) {
                            formData["captcha"] = {};
                            formData["captcha"]["response"] = this.captchaToken;
                        }
                        this.store.callUpdateProfileInfo(formData, true, this, type).then(() => {
                            if (this.form.file.name) {
                                this.uploadImage();
                            }
                        });
                    });
                });
            },
            submit() {
                load(`${this.static_vars.recaptchaKey}`, { useRecaptchaNet: true }).then((recaptcha) => {
                    recaptcha.execute("login").then((token) => {
                        // console.log('token',token) // Will print the token
                        this.captchaToken = token;
                        let formData = {};
                        formData["countryId"] = this.country.id;
                        formData["countryIsoCodeA3"] = this.country.isoCode;
                        formData["customerId"] = this.store.userDetail.customerId;
                        formData["firstName"] = this.form.fname;
                        formData["lastName"] = this.form.lname;
                        // formData["profileName"] = this.form.username;
                        if (!this.store.userDetail.emailVerified && this.store.userDetail.email != this.form.email) {
                            formData["email"] = this.form.email;
                        }
                        if (this.store.userDetail.phoneNumber != this.form.mobile) {
                            formData["phoneCountryCode"] = this.form.phonecode;
                            formData["phoneNumber"] = this.form.mobile;
                        }
                        if (this.captchaToken) {
                            formData["captcha"] = {};
                            formData["captcha"]["response"] = this.captchaToken;
                        }
                        if(!Object.keys(this.country).length){
                            this.isCountry = true
                        } else {
                            this.isCountry = false
                            // if((this.form.fname != this.store.userDetail?.firstName) || (this.form.lname != this.store.userDetail.lastName)){
                                
                            // }
                            if(this.form.mobile){
                                this.isPhone = true
                                this.store.callUpdateProfileInfo(formData, true, this).then((res)=>{
                                    if(res){ this.successToast() }
                                })
                                if(this.form.username && (this.form.username != this.store.userDetail?.profileName)){
                                    this.submitName()
                                }
                            }else{
                                this.isPhone = false
                            }
                        }
                        if (this.form.file.name) {
                            this.uploadImage();
                        }
                    });
                });
            },
            submitName(){
                this.store.userNamePUT({'name':this.form.username},false,this)
            },
            clearDoc() {
                document.getElementById("fileUpload").value = "";
                this.form.file = {};
                this.form.name = "";
            },
            uploadDoc() {
                document.getElementById("fileUpload").click();
            },
            handleOnChange(value) {
                this.form.emailcode = value;
            },
            validEmail() {
                let regexp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                return regexp.test(this.form.email.toLowerCase());
            },
            getTime() {
                var date = new Date();
                return date.getTime();
            },
            addfileImage(file) {
                return URL.createObjectURL(file);
            },
            setImage(event) {
                if (event.target.files[0]) {
                    this.form.file = event.target.files[0];
                    this.form.name = this.form.file.name;
                }
            },
            getAllowCountry(){
                return Object.values(this.store.countriesList).map((val) => val.isoCode) || [];
            },
            getCountryData(code) {
                if (Object.keys(this.store.countriesList).length && code) {
                    return Object.values(this.store.countriesList).filter((val) => val.isoCodeA3 == code)[0] || {};
                }
            },
            changecountry(item) {
                this.$refs.phone.choose(item.isoCode);
            },
            onInput(e) {
                this.form.mobile = e.target.value;
            },
            GetCode(e) {
                this.form.phonecode = e.dialCode;
            },
            callUserDetail() {
                this.store.getUserDetail({}, true, this, this.type);
            },
            uploadImage() {
                let formData = new FormData();
                if (this.form.file.name) {
                    formData.append("avatar", this.form.file);
                }
                this.store.uploadUserImage(formData, true).then((response) => {
                    if (response === true) {
                        this.clearDoc();
                        this.callUserDetail();
                        // if(this.store.userDetail.emailVerified && this.store.userDetail.phoneVerified){
                        //     this.$router.replace('/add-trading-account')
                        // }
                    }
                });
            },
            handleOnComplete(value) {
                this.form.emailcode = value;
            },
            getEmailCode() {
                load(`${this.static_vars.recaptchaKey}`, { useRecaptchaNet: true }).then((recaptcha) => {
                    recaptcha.execute("login").then((token) => {
                        // console.log('token',token) // Will print the token
                        this.captchaToken = token;
                        if (this.captchaToken) {
                            let formData = {};
                            formData["identifier"] = "EMAIL";
                            formData["email"] = this.store.userDetail.email;
                            if (this.captchaToken) {
                                formData["captcha"] = {};
                                formData["captcha"]["response"] = this.captchaToken;
                            }
                            this.store.resendOTP(formData, true, this).then(() => {
                                this.showEmailCode = true;
                                this.type = "";
                            });
                        }
                    });
                });
            },
            refreshToken() {
                let form = {
                    grant_type: "refresh_token",
                    refresh_token: this.store.user.refresh_token,
                };
                this.store.refreshToken(form, false, "basic", this, "personal-detail").then(() => {
                    // if (response.access_token) {
                    //     this.store.getFlavorID({}, false);
                    // }
                });
            },
            verifyEmailOTP() {
                load(`${this.static_vars.recaptchaKey}`, { useRecaptchaNet: true }).then((recaptcha) => {
                    recaptcha.execute("login").then((token) => {
                        // console.log('token',token) // Will print the token
                        this.captchaToken = token;
                        if (this.form.emailcode && this.captchaToken) {
                            let formData = {};
                            formData["code"] = this.form.emailcode;
                            formData["email"] = this.store.userDetail.email;
                            if (this.captchaToken) {
                                formData["captcha"] = {};
                                formData["captcha"]["response"] = this.captchaToken;
                            }
                            this.store.verifyOTP(formData, true, this).then((response) => {
                                if (response === true) {
                                    this.showEmailCode = false;
                                    let data = this.store.userDetail;
                                    data.emailVerified = true;
                                    this.store.$patch({ userDetail: data });
                                }
                            });
                        }
                    });
                });
            },
            CretioFunc() {
                try {
                    window.gtag("event", "email_verify");
                    window.fbq('trackCustom', 'email_verify', {'Event ID' : this.store.customerDetail?.id ,'email' : this.store.customerDetail?.email,'value' : '20','country' : this.store.flavordata?.userCountry?.name});
                    if (Object.keys(this.store.flavordata).length && this.store.flavordata.userCountry && Object.keys(this.store.flavordata.userCountry).length && this.store.flavordata.userCountry.isoCode) {
                        for (var key in this.static_vars.cretioCountryJson) {
                            if (key == this.store.flavordata.userCountry.isoCode) {
                                var deviceType = /iPad/.test(navigator.userAgent) ? "t" : /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Silk/.test(navigator.userAgent) ? "m" : "d";
                                window.criteo_q.push(
                                    { event: "setaccount", account: this.static_vars.cretioCountryJson[key] }, // You should never update this line
                                    { event: "setSiteType", type: deviceType},
                                    //{ event: "setemail", email: this.Crypto.SHA256(this.store.customerDetail.email).toString() , hash_method: "sha256" },
                                    { event: "setemail", email: this.Crypto.SHA256('1').toString() , hash_method: "sha256" },
                                    { event: "trackTransaction", id: (Math.random() * 50000), item: [
                                        {id : Math.ceil(Math.random()*10300 * Math.random() * 50000), price:1, quality : 1}
                                    ]}
                                );
                            }
                        }
                    }
                } catch (e) {
                    console.log("cretio ex", e);
                }
            },
            verifySMSOTP() {
                load(`${this.static_vars.recaptchaKey}`, { useRecaptchaNet: true }).then((recaptcha) => {
                    recaptcha.execute("login").then((token) => {
                        // console.log('token',token) // Will print the token
                        this.captchaToken = token;
                        if (this.form.smscode && this.captchaToken) {
                            let formData = {};
                            formData["code"] = this.form.smscode;
                            //formData['email'] = this.store.userDetail.email
                            if (this.captchaToken) {
                                formData["captcha"] = {};
                                formData["captcha"]["response"] = this.captchaToken;
                            }
                            this.store.verifyMobileOTP(formData, true, this).then(() => {
                                this.showSMSCode = false;
                                this.showOtp = false;
                                this.validPhone = false;
                                 this.PhoneInstruction = false;
                                this.type = "";
                                this.form.smscode = "";
                                let data = this.store.userDetail;
                                data.phoneVerified = true;
                                this.store.$patch({ userDetail: data });
                            });
                        }
                    });
                });
            },
            SendSMS() {
                load(`${this.static_vars.recaptchaKey}`, { useRecaptchaNet: true }).then((recaptcha) => {
                    recaptcha.execute("login").then((token) => {
                        // console.log('token',token) // Will print the token
                        this.captchaToken = token;
                        if (this.captchaToken) {
                            let formData = {};
                            if (this.captchaToken) {
                                formData["captcha"] = {};
                                formData["captcha"]["response"] = this.captchaToken;
                            }
                            this.store.sendOtpMobile(formData, true, this).then((response) => {
                                if(response === 6966){
                                    this.PhoneInstruction = true
                                }else if (response === true) {
                                    this.showSMSCode = true;
                                    this.showOtp = true
                                    this.PhoneInstruction = false
                                    this.getCount()
                                }
                            });
                        }
                    });
                });
            },
        },
        mounted() {
            load(`${this.static_vars.recaptchaKey}`, { useRecaptchaNet: true }).then((recaptcha) => {
                recaptcha.execute("login").then((token) => {
                    // console.log(token) // Will print the token
                    this.captchaToken = token;
                });
            });
            if (Object.keys(this.store.userDetail).length) {
                this.form.email = this.store.userDetail.email ? this.store.userDetail.email : "";
                this.form.fname = this.store.userDetail.firstName ? this.store.userDetail.firstName : "";
                this.form.lname = this.store.userDetail.lastName ? this.store.userDetail.lastName : "";
                if (this.store.userDetail.countryIsoCodeA3) {
                    this.changecountry(this.getCountryData(this.store.userDetail.countryIsoCodeA3));
                }
                this.form.mobile = this.store.userDetail.phoneNumber ? this.store.userDetail.phoneNumber : "";
                if (this.form.mobile) {
                    this.form.mobile = this.form.mobile.toString();
                }
                this.country = this.store.userDetail.countryIsoCodeA3 ? this.getCountryData(this.store.userDetail.countryIsoCodeA3) : "";
                this.form.username = this.store.userDetail.profileName ? this.store.userDetail.profileName : "";
            }
            const _that = this;
            $(document).on("click", function (event) {
                if (!$(event.target).closest(".filter-dropdown").length) {
                    _that.selectButton = false;
                }
            });
        },
        created() {
            //this.callUserDetail()
        },
    };
</script>
