<template>
    <div class="col-12 col-lg-9">
        <section class="userSteps bg-white boxed max-1085 p-md-4">
            <div class="row">
                <div class="col-12 mb-4" v-if="store.isLeaderList.length">
                    <div class="row align-items-center">
                        <div class="col-12 col-lg-9">
                            <h3 class="medium mb-3 f-28 darkblueColor"> {{$t('changes.text8')}} </h3>
                            <p v-html="$t('changes.text9')"></p>
                        </div>
                        <div class="col-12 col-lg-3 form-group">
                            <select class="form-select form-control w-100 radius-0" v-model="provideId">
                                <option value="">{{$t('changes.text10')}}</option>
                                <option v-for="account in store.isLeaderList" :value="account.providerId" :key="account.providerId">{{account.providerName}}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="col-12" v-if="provideId">
                    <div class="row horizontalWidget">
                        <div class="col-12">
                            <h4>{{$t('changes.text11')}}</h4>
                            <p>{{$t('changes.text12')}}</p>
                        </div>
                        <div class="col-12 col-lg-7 frameWidget">
                            <h5>{{$t('changes.text13')}}</h5>
                            <div class="overflow-auto">
                                <iframe
                                    id="small-banner"
                                    :src="`${static_vars.domainURL}widgets/trader-info/${provideId}?lang=en&utm_source=${provideId}&utm_medium=refferal&utm_campaign=horizontaltraderwidget`"
                                    width="500"
                                    height="440"
                                    scrolling="no"
                                    style="border: 0px solid rgb(216, 221, 230); border-radius: 3px;"
                                ></iframe>
                            </div>
                        </div>
                        <div class="col-12 col-lg-5 widgetCode">
                            <h5>{{$t('changes.text14')}}</h5>
                            <div class="position-relative form-group">
                                <textarea readonly :placeholder="horizontalWidgetCode()" :value="horizontalWidgetCode()" class="float-field form-control pt-0"></textarea>
                                <div class="position-absolute coppied">
                                    <button class="copyButton" type="button" @click="copy(horizontalWidgetCode(),'copyMsg')"><vue-feather size="25" type="copy"></vue-feather></button>
                                    <span class="coppytext" v-if="copyMsg">{{copyMsg}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row verticalWidget">
                        <div class="col-12">
                            <h4>{{$t('changes.text15')}}</h4>
                            <p>{{$t('changes.text16')}}</p>
                        </div>
                        <div class="col-12 col-lg-7 frameWidget">
                            <h5>{{$t('changes.text17')}}</h5>
                            <div class="overflow-auto">
                                <iframe
                                    id="small-banner"
                                    :src="`${static_vars.domainURL}widgets/trader-info/${provideId}?&bt=s&lang=en&utm_source=${provideId}&utm_medium=refferal&utm_campaign=verticaltraderwidget`"
                                    width="250"
                                    height="600"
                                    scrolling="no"
                                    style="border: 0px solid rgb(216, 221, 230); border-radius: 3px;"
                                ></iframe>
                            </div>
                        </div>
                        <div class="col-12 col-lg-5 widgetCode">
                            <h5>{{$t('changes.text18')}} </h5>
                            <div class="position-relative form-group">
                                <textarea readonly :placeholder="verticalWidgetCode()" :value="verticalWidgetCode()" class="float-field form-control pt-0"></textarea>
                                <div class="position-absolute coppied">
                                    <button class="copyButton" type="button" @click="copy(horizontalWidgetCode(),'copyVMsg')"><vue-feather size="25" type="copy"></vue-feather></button>
                                    <span class="coppytext" v-if="copyVMsg">{{copyVMsg}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
    import { myStore } from "@/store/pinia-store";
    export default {
        setup() {
            const store = myStore();
            return { store };
        },
        data() {
            return {
                provideId: "",
                copyMsg: "",
                copyVMsg : ""
            };
        },
        methods: {
            // getLeaderList() {
            //     if (this.store.userTradingAccountsList.length) {
            //         let json = this.store.userTradingAccountsList.filter((i) => i.demo === false).find((v) => v.isLeader);
            //         let data = [];
            //         if (Object.keys(json).length) {
            //             data.push(json);
            //         }
            //         return data;
            //     } else {
            //         return [];
            //     }
            // },
            horizontalWidgetCode() {
                return `<iframe id="small-banner" src="${this.static_vars.domainURL}widgets/trader-info/${this.provideId}?&lang=en&utm_source=${this.provideId}&utm_medium=refferal&utm_campaign=horizontaltraderwidget" width="500" height="440" scrolling="no" style="border: 0px solid rgb(216, 221, 230); border-radius: 3px; padding-top: 25px;"></iframe>`;
            },
            verticalWidgetCode() {
                return `<iframe id="small-banner" src="${this.static_vars.domainURL}widgets/trader-info/${this.provideId}?&bt=s&lang=en&utm_source=${this.provideId}&utm_medium=refferal&utm_campaign=verticaltraderwidget" width="250" height="600" scrolling="no" style="border: 0px solid rgb(216, 221, 230); border-radius: 3px; padding-top: 25px;"></iframe>`;
            },
            copy(text,val) {
                try {
                    navigator.clipboard.writeText(text);
                    this[val] = "Copied";
                    setTimeout(() => {
                        this[val] = "";
                    }, 3000);
                } catch (e) {
                    this[val] = "Unable to copy";
                    setTimeout(() => {
                        this[val] = "";
                    }, 3000);
                }
            },
        },
        created(){
            if(this.store.isLeaderList.length){
                this.provideId = this.store.isLeaderList[0].providerId
            }
        }
    };
</script>
